.containerBox {
  width: 100%;
  max-width: 1200px;
}
.logo {
  width: 100%;
  max-width: 600px;
  float: right;
  margin-top: 200px;
}
.contVerse {
  width: 100%;
  padding-top: 20px;
  text-align: right;
}
