.formBox {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.logoejc {
  width: 100%;
  max-width: 400px;
  margin-top: 120px;
}
.containerCenter {
  text-align: center;
}
