.txtSign {
  color: #3d3d3d;
  margin-top: 20px !important;
}

.txtValue {
  font-size: 78px !important;
  color: #59eafd;
}

.txtCents {
  margin-top: 20px !important;
}

.txtSubmit {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 900 !important;
}

.txtInfo {
  color: #59eafd;
  font-size: 12px !important;
  text-align: right;
}

.soldout {
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 99;
}

.cardDisable {
  opacity: 0.2;
}
