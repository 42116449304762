.footer {
  display: flex;
  width: 100%;
  height: 62px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.containerBox {
  width: 100%;
  max-width: 1200px;
}
