.containerForm {
  width: 100%;
  padding-top: 30px;
}

.formBox {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 80px;
}

.boxTxt {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.qrcode {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.copyText {
  cursor: pointer;
  word-wrap: break-word;
  white-space: pre-wrap;
}
